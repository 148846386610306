<template>
  <div>
    <van-tabbar route :route_data="footer_data" >
      <van-tabbar-item v-for="(item) in footer_data" :key="item.url"  replace :to="item.url" :icon="item.icon">{{item.title}}</van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>

export default {
  name: "v-footer",
  props:["footer_data"],
  data() {
    // 这里存放数据
    return {}
  },
  // import 引入的组件需要注入到对象中才能使用
  
}
</script>

<style scoped>
.van-tabbar-item--active{
  font-weight: bold !important;
}
</style>
