import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    redirect: 'home',
  },
  {
    path: '/orderList',
    name: 'orderList',
    meta: {
      title: '订餐页面',
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/order/index.vue')
  },
  {
    path: '/orderDetail',
    name: 'orderDetail',
    meta: {
      title: '订餐详情页',
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/order/orderDetail.vue')
  },
  // {
  //   path: '/login',
  //   name: 'login',
  //   meta: {
  //     title: '登陆页面',
  //   },
  //   component: () => import(/* webpackChunkName: "about" */ '../views/login/login.vue')
  // },
  {
    path: '/myOrder',
    name: 'myOrder',
    meta: {
      title: '我的订单',
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/myOrder/myOrder.vue')
  },
  {
    path: '/paySuccess',
    name: 'paySuccess',
    meta: {
      title: '付款成功',
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/order/paySuccess.vue')
  },
  {
    path: '/home',
    name: 'home',
    meta: {
      title: '生活区首页',
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/home/home.vue')
  },
  {
    path: '/water',
    name: 'water',
    meta: {
      title: '水费查询',
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/cost/water.vue')
  },
  {
    path: '/elect',
    name: 'elect',
    meta: {
      title: '电费查询',
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/cost/elect.vue')
  },
  {
    path: '/stay',
    name: 'stay',
    meta: {
      title: '住宿费查询',
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/cost/stay.vue')
  },
  {
    path: '/mine',
    name: 'mine',
    meta: {
      title: '我的',
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/mine/index.vue')
  },
  {
    path: '/carelist',
    name: 'carelist',
    meta: {
      title: '我的卡券',
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/care/list.vue')
  },
  {
    path: '/caredetail',
    name: 'caredetail',
    meta: {
      title: '卡券明细',
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/care/detail.vue')
  },
  {
    path: '/carehistory',
    name: 'carehistory',
    meta: {
      title: '卡券历史',
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/care/history.vue')
  },
  {
    path: '/questionList',
    name: 'questionList',
    meta: {
      title: '调查问卷',
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/question/index.vue')
  },
  {
    path: '/formQuestion',
    name: 'formQuestion',
    meta: {
      title: '调查问卷',
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/question/formQuestion.vue')
  },
  {
    path: '/addrepair',
    name: 'addrepair',
    meta: {
      title: '在线报修',
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/repair/add_repair.vue')
  },
  {
    path: '/repairlist',
    name: 'repairlist',
    meta: {
      title: '报修列表',
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/repair/repair_history.vue')
  },
  {
    path:'/waitrepair/msg',
    name: 'waitrepairmsg',
    meta: {
      title: '报修推送',
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/waitrepair/repair_msg.vue')
  },
  {
    path:'/waitrepair/msglist',
    name: 'waitrepairmsglist',
    meta: {
      title: '报修推送历史',
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/waitrepair/repair_msglist.vue')
  },
  {
    path: '/waitrepair',
    name: 'waitrepair',
    meta: {
      title: '待处理报修',
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/waitrepair/wait_repair.vue')
  },
  {
    path: '/repair_record',
    name: 'repair_record',
    meta: {
      title: '待处理维修',
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/waitrepair/repair_record.vue')
  },
  {
    path: '/insiderepair',
    name: 'insiderepair',
    meta: {
      title: '内修',
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/waitrepair/inside_repair.vue')
  },
  {
    path: '/outsiderepair',
    name: 'outsiderepair',
    meta: {
      title: '外修',
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/waitrepair/outside_repair.vue')
  },
  {
    path: '/staffwater',
    name: 'staffwater',
    meta: {
      title: '员工水费上传',
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/ocr/staffwater.vue')
  },
  {
    path: '/adminwater',
    name: 'adminwater',
    meta: {
      title: '舍监水电工水费上传',
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/ocr/adminwater.vue')
  },
  {
    path: '/adminelec',
    name: 'adminelec',
    meta: {
      title: '舍监水电工电费上传',
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/ocr/adminelec.vue')
  },
  {
    path: '/waterhistory',
    name: 'waterhistory',
    meta: {
      title: '舍监水电工水费记录',
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/ocr/waterhistory.vue')
  },
  {
    path: '/elechistory',
    name: 'elechistory',
    meta: {
      title: '舍监水电工电费记录',
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/ocr/elechistory.vue')
  },
  {
    path: '/staffhistory',
    name: 'staffhistory',
    meta: {
      title: '员工水费记录',
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/ocr/staffhistory.vue')
  },
  {
    path: '/bottledwater',
    name: 'bottledwater',
    meta: {
      title: '桶装水下单',
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/bottledWater/index.vue')
  },
  {
    path: '/bottledwaterhistory',
    name: 'bottledwaterhistory',
    meta: {
      title: '桶装水下单历史记录',
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/bottledWater/history.vue')
  },
  {
    path: '/retreat',
    name: 'retreat',
    meta: {
      title: '退宿检查表',
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/checkout/index.vue')
  },
  {
    path: '/retreathistory',
    name: 'retreathistory',
    meta: {
      title: '退宿检查表历史',
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/checkout/history.vue')
  },
  {
    path: '/visitor/index',
    name: 'visitorhome',
    meta: {
      title: '访客首页',
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/visitor/index.vue')
  },
  {
    path: '/visitor/bind',
    name: 'visitorbind',
    meta: {
      title: '访客绑定',
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/visitor/bind.vue')
  },
  {
    path: '/visitor/visit_bind',
    name: 'bind',
    meta: {
      title: '员工绑定',
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/visitor/visit_bind.vue')
  },
  {
    path: '/visitor/info',
    name: 'visitorinfo',
    meta: {
      title: '访客个人信息',
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/visitor/info.vue')
  },
  {
    path: '/visitor/infoedit',
    name: 'visitorinfoedit',
    meta: {
      title: '访客个人信息修改',
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/visitor/info_edit.vue')
  },
  {
    path: '/visitor/visitinfo',
    name: 'visitinfo',
    meta: {
      title: '员工个人信息',
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/visitor/visit_info.vue')
  },
  {
    path: '/visitor/visitinfoedit',
    name: 'visitinfoedit',
    meta: {
      title: '员工个人信息修改',
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/visitor/visit_info_edit.vue')
  },
  {
    path: '/visitor/prebook',
    name: 'prebook',
    meta: {
      title: '访客预约单',
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/visitor/pre_book_new.vue')
  },
  {
    path: '/visitor/staffindex',
    name: 'staffindexhome',
    meta: {
      title: '员工首页',
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/visitor/visit_list.vue')
  },
  {
    path: '/visitor/for_visitlist',
    name: 'forvisitlist',
    meta: {
      title: '受访列表',
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/visitor/visit_for_visitlist.vue')
  },
  {
    path: '/visitor/for_visitlist_show',
    name: 'forvisitlist_show',
    meta: {
      title: '受访详情',
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/visitor/visit_for_visitlist_show.vue')
  },
  {
    path: '/visitor/pre_for_booklist',
    name: 'pre_for_booklist',
    meta: {
      title: '受邀列表',
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/visitor/pre_for_booklist.vue')
  },
  {
    path: '/visitor/pre_for_booklist_show',
    name: 'pre_for_booklistshow',
    meta: {
      title: '受邀详情',
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/visitor/pre_for_booklist_show.vue')
  },
  {
    path: '/visitor/visit',
    name: 'visited',
    meta: {
      title: '员工邀约单',
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/visitor/visit_new.vue')
  },
  {
    path: '/visitor/preshow',
    name: 'visitorshow',
    meta: {
      title: '预约单查看',
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/visitor/pre_book_show.vue')
  },
  {
    path: '/visitor/visitshow',
    name: 'visitorvisitshow',
    meta: {
      title: '邀约单查看',
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/visitor/visit_show.vue')
  },
  {
    path: '/visitor/QRCodeshow',
    name: 'visitorQRCodeshow',
    meta: {
      title: '二维码查看',
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/visitor/QRCode_detail.vue')
  },
  {
    path: '/sanitaryinspection',
    name: 'sanitaryinspection',
    meta: {
      title: '卫生检查',
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/ocr/sanitaryinspection.vue')
  },
  {
    path: '/sanitarylist',
    name: 'sanitarylist',
    meta: {
      title: '卫生查询',
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/cost/sanitary.vue')
  },
  {
    path: '/masteraddrepair',
    name: 'masteraddrepair',
    meta: {
      title: '舍监手机端在线维修',
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/repair/master_add_repair.vue')
  },
  {
    path: '/integralevaluation',
    name: 'integralevaluation',
    meta: {
      title: '积分评价',
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/ocr/integralevaluation.vue')
  },
  {
    path: '/integrallist',
    name: 'integrallist',
    meta: {
      title: '积分查询',
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/cost/integral.vue')
  },
  {
    path: '/integralmy',
    name: 'integralmy',
    meta: {
      title: '个人积分查询',
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/cost/integralmy.vue')
  },
  {
    path: '/healthlist',
    name: 'healthlist',
    meta: {
      title: '卫生检查列表',
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/health/list.vue')
  },
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
